@font-face {
  font-family: 'Nightmare';
  src: local('Nightmare'), url(./fonts/nightmare-font/NightmareRegular-vmOzA.ttf) format('truetype');
}

@font-face {
  font-family: 'Contagion';
  src: local('Contagion'), url(./fonts/contagion-font/Contagion.ttf) format('truetype');
}


@font-face {
  font-family: 'Covid19';
  src: local('Covid19'), url(./fonts/covid-19-font/covid19.ttf) format('truetype');
}


body {
  margin: 0;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "white"
}

